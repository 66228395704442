<template>
  <div>
    <loader v-if="loading" />
    <template v-else>

      <div class="mb-4">
        <!-- Info Guide -->
        <info-guide
          v-if="guideVisibleFirstTime"
          type="directCostsExamples"
          visible
          @click="closeInfoFirstTime"
        />
        <!-- /Info Guide -->

        <div class="d-flex justify-content-end mb-4 margin-guide-not-visible">
          <erase-icon-button
            v-if="removeButtonVisible"
            @click="onDelete"
          />
        </div>
      </div>

      <template v-if="!isDirectCostsEmpty">
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <distribution-of-direct-costs />
        </ib-card>
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <direct-costs-over-time />
        </ib-card>
        <ib-card
          :animation="false"
          class="pt-4 pb-2"
        >
          <direct-costs-table />
        </ib-card>
      </template>

      <empty-placeholder v-else />
    </template>

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="directCostsExamples"
      :visible="guideVisible"
      @close="guideVisible = false;"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="direct-costs"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Direct Cost -->
    <dialog-add-direct-cost
      v-if="canEdit"
      :dialog-add-direct-cost-visible="dialogAddNewVisible"
      @close-dialog-add-direct-cost="dialogAddNewVisible = false"
      @close-dialog-on-button="dialogAddNewVisible = false"
      @on-update-direct-streams-view="onUpdateAddNewDialog()"
    />
    <!-- /Dialog Add Direct Cost -->
  </div>
</template>

<script>
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import DialogAddDirectCost from '@/views/Home/StoryMode/Forecast/DirectCosts/Dialogs/DialogAddDirectCost'
import DirectCostsOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/DirectCost/DirectCostsOverTime'
import DirectCostsTable from '@/views/Home/StoryMode/Forecast/DirectCosts/Tables/DirectCostsTable'
import DistributionOfDirectCosts from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/DirectCost/DistributionOfDirectCosts'
import EmptyPlaceholder from '@/views/Home/StoryMode/FinancialPlan/Components/EmptyPlaceholder'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import EventBus from '@/event-bus'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'
import Loader from '@/components/Loader'
import MixinAiBuddyDrawer from '@/mixins/aiBuddyDrawer'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { mapState } from 'vuex'

export default {
  name: 'ExpensesDirectCosts',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    DialogAddDirectCost,
    DirectCostsOverTime,
    DirectCostsTable,
    DistributionOfDirectCosts,
    EmptyPlaceholder,
    EraseIconButton,
    Loader
  },

  mixins: [MixinApiCalculations, MixinGuide, MixinIdeaRoles, MixinDeleteIdeaExamples, MixinAiBuddyDrawer],

  data () {
    return {
      loading: false,
      dialogAddNewVisible: false
    }
  },

  computed: {
    ...mapState(['idea']),

    isDirectCostsEmpty () {
      return this.idea.storyMode.forecast.directCosts.directCosts.length === 0
    },

    removeButtonVisible () {
      return this.canEdit && this.directCostHasItemsFromTemplate
    }
  },

  mounted () {
    this.openGuide('directCostFinancialPlanGuide')
    EventBus.$on('add-edit', () => {
      this.onShowAddNewDialog()
    })

    EventBus.$on('open-info', () => {
      this.guideVisible = true
    })

    EventBus.$on('close-info-first-time', () => {
      this.guideVisibleFirstTime = false
    })

    EventBus.$on('remove-examples', () => {
      this.deleteExamples()
    })
  },

  beforeDestroy () {
    EventBus.$off('add-edit')
    EventBus.$off('open-info')
    EventBus.$off('remove-examples')
    EventBus.$off('close-info-first-time')
  },

  methods: {
    onShowAddNewDialog () {
      this.getDirectCosts()
      this.dialogAddNewVisible = true
    },

    onUpdateAddNewDialog () {
      this.loading = true
      Promise.all([
        this.getDirectCosts(),
        this.getTotalCosts(),
        this.getProfitAndCashFlow()
      ])
        .then(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteExamples () {
      this.deleteExamplesMixin('direct-costs')
        .then(_ => { this.onUpdateAddNewDialog() })
    }
  }
}
</script>
